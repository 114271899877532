
<script>
export default {
  data() {

  },
  methods:{
    base64ToBlob(base64Data, mimeType) {
      const byteCharacters = atob(base64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: mimeType });
      return blob;
    },

    // 复制
    async copyImage(base64Image) {
      // 从Base64字符串中提取数据类型和编码数据
      const matches = base64Image.match(/^data:(.*);base64,(.*)$/);
      if (!matches || matches.length !== 3) {
        alert('Invalid Base64 string');
        return;
      }

      // 分离出数据类型和编码的图像数据
      const mimeType = matches[1];
      const base64Data = matches[2];

      // 将Base64数据转换为Blob对象
      const blob = this.base64ToBlob(base64Data, mimeType);

      // 将Blob对象写入剪切板
      try {
        await navigator.clipboard.write([
          new ClipboardItem({
            [mimeType]: blob,
          }),
        ]);
        this.$message.success('复制成功！')
        this.QRCodehandleCancel();
      } catch (error) {
        this.$message.error('复制到剪切板失败')
        console.error('复制到剪切板失败:', error);
      }
    },
    // 生成二维码
    copyQRCode() {
      // 校验必填
      if(!this.QRCodemodelForm.price) {
        return this.$message.warning('请输入付款金额！')
      }

      this.QRCodemodelLoading = true;
      this.$ajax({
        url: '/hxclass-management/product-archives/buildOfflineProductsQRCode',
        method: 'POST',
        params: this.QRCodemodelForm
      }).then(async res=>{
        var base64Image = "data:image/png;base64," + res;
        this.imageSrc = base64Image;
        this.QRCodehandleCancel(); // 关闭生成二维码弹框
        this.QRCodeImgvisible = true; // 打开二维码预览弹框

        // // 图片下载
        // this.downloadBase64Image(base64Image,'收款码')

        
        // try {
        //   // http协议下navigator.clipboard方法识别不到
        //   var base64Image = "data:image/png;base64," + res;
        //   const blobInput = this.base64ToBlob(base64Image, 'image/png');
        //   const clipboardItemInput = new ClipboardItem({ 'image/png': blobInput });
        //   await navigator.clipboard.write([clipboardItemInput]);
          
        //   this.$message.success('复制成功！')
        //   this.QRCodehandleCancel();
        // }catch(err){
        //   console.error(err)
        // }        
      })
    },

    // base64ToBlob(base64Data, contentType) {
    //   // base64 解码
    //   let byteString = window.atob(base64Data.substring(base64Data.indexOf(',') + 1));
    //   // 创建缓冲数组
    //   let arrayBuffer = new ArrayBuffer(byteString.length);
    //   // 创建视图
    //   let intArray  = new Uint8Array(arrayBuffer);
    //   for (let i = 0; i < byteString.length; i++) {
    //       intArray[i] = byteString.charCodeAt(i);
    //   }
    //   return new Blob([intArray], { type: contentType });
    // },

    // 下载二维码
    downloadBase64Image(base64Data, filename = 'image.png') {  
      // 从Base64字符串中提取数据类型和编码数据
      const matches = base64Data.match(/^data:(.*);base64,(.*)$/);
      if (!matches || matches.length !== 3) {
        alert('Invalid Base64 string');
        return;
      }

      // 分离出数据类型和编码的图像数据
      const mimeType = matches[1];
      const base64DataStr = matches[2];

      // 将Base64数据转换为Blob对象
      const blob = this.base64ToBlob(base64DataStr, mimeType);  
    
      // 创建一个新的a标签  
      const a = document.createElement('a');  
    
      // 创建一个URL对象，指向Blob对象  
      const url = window.URL.createObjectURL(blob);  
    
      // 设置a标签的href属性为Blob对象的URL  
      a.href = url;  
    
      // 设置下载的文件名  
      a.download = filename;  
    
      // 触发a标签的点击事件以开始下载  
      document.body.appendChild(a); // 需要添加到DOM中，否则在Firefox中可能无法正常工作  
      a.click();  
    
      // 清理，从DOM中移除a标签，并释放URL对象  
      // document.body.removeChild(a);  
      window.URL.revokeObjectURL(url);  
    }  
  }
}
</script>